import React, { FC } from 'react';
import { renderImage } from '@lib/utils';

import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import { Format } from '@lib/types';
import { Icon } from '@components/Atoms';
import { rem } from 'polished';
interface TextWithMediaI {
  display: boolean;
  title: string;
  internalLink?: string;
  media: any;
  subtitle?: string;
  subIcons?: {
    icon: string;
    alt: string;
  }[];
  bodyContent?: string;
}

const STYLEDICON = {
  wrapper: styled.div`
    flex: 1 1 calc(50% - 1rem);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 1rem;

    img {
      object-fit: contain;
    }

    @media ${breakpoints.tablet} {
      gap: 1.5rem;

      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
      }
    }
  `,
  content: styled.div`
    p {
      margin-bottom: 0;
      font-weight: 600;
      font-size: ${rem(20)};

      @media ${breakpoints.tablet} {
        font-size: ${rem(24)};
      }
    }
  `,
};

const STYLED = {
  wrapper: styled.section`
    & > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media ${breakpoints.desktop} {
        flex-direction: row;
        gap: 4rem;
        align-items: start;
        justify-content: start;
      }

      @media ${breakpoints.desktopLarge} {
        gap: 6rem;
      }
    }
  `,
  media: styled.div`
    @media ${breakpoints.desktop} {
      flex: 1 1 40%;
      min-height: 400px;
    }
  `,
  content: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media ${breakpoints.desktop} {
      flex: 1 1 50%;
      gap: 0;
    }
  `,
  title: styled.h2`
    font-size: ${rem(30)};
    color: var(--color-primary);
    margin-right: auto;

    @media ${breakpoints.tablet} {
      font-size: ${rem(40)};
    }

    @media ${breakpoints.desktopLarge} {
      font-size: ${rem(53)};
    }
  `,
  icons: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;

    @media ${breakpoints.tablet} {
      gap: 2rem 1rem;
    }

    @media ${breakpoints.desktop} {
      display: none;
    }
  `,
  subtitle: styled.p`
    display: none;

    @media ${breakpoints.tablet} {
      display: block;
      font-weight: 600;
      font-size: ${rem(22)} !important;

      @media ${breakpoints.desktop} {
        font-size: ${rem(25)} !important;
      }
    }
  `,
  body: styled.p``,
};

const IconCard = ({ icon }) => {
  return (
    <STYLEDICON.wrapper>
      <Icon md icon={icon.icon[0].url} altText={icon.icon.alternativeText} />
      <STYLEDICON.content>
        <p>{icon.title}</p>
      </STYLEDICON.content>
    </STYLEDICON.wrapper>
  );
};

export const TextWithMedia: FC<TextWithMediaI> = ({
  display,
  title,
  internalLink,
  media,
  subtitle,
  subIcons,
  bodyContent,
}) => {
  if (!display) return null;

  const IconCards = subIcons?.map((icon, key) => {
    const Component = <IconCard key={key} icon={icon} />;

    return Component;
  });

  return (
    <STYLED.wrapper id={internalLink} className="slice">
      <div className="slice__inner flex-col">
        <STYLED.media>
          {media.mime.startsWith('image/') ? (
            renderImage({
              image: media,
              format: Format.MEDIUM,
            })
          ) : media.mime.startsWith('video/') ? (
            <video autoPlay loop muted playsInline>
              <source src={media.url} type={media.mime} />
              Your browser does not support the video tag.
            </video>
          ) : null}
        </STYLED.media>

        <STYLED.content>
          <STYLED.title
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          {subIcons && <STYLED.icons>{IconCards}</STYLED.icons>}
          {subtitle && (
            <STYLED.subtitle
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          )}
          {bodyContent && (
            <STYLED.body
              dangerouslySetInnerHTML={{
                __html: bodyContent,
              }}
            />
          )}
        </STYLED.content>
      </div>
    </STYLED.wrapper>
  );
};
