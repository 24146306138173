import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';

const StyledPreTitle = styled.p`
  color: var(--colors-primary);
  font-weight: 500;
  font-family: var(--font-geomanist);
  font-size: ${rem(24)};
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  line-height: 1;

  @media (${breakpoints.tablet}) {
    font-size: ${rem(28)};
  }
`;

const StyledTitle = styled.h1<{
  addHeadingGradient: boolean;
  isHome?: boolean;
}>`
  color: var(--colors-primary);
  font-size: ${rem(60)};
  line-height: 1;
  ${({ addHeadingGradient }) =>
    addHeadingGradient &&
    `
        background: -webkit-linear-gradient(180deg, #f4111b 30%, #0402fd);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
    `}
  @media (${breakpoints.mobilelg}) {
    font-size: ${rem(72)};
  }
  @media (${breakpoints.tablet}) {
    max-width: clamp(50%, 100%, 50rem);
    font-size: ${rem(90)};
  }
  @media (${breakpoints.desktopLarge}) {
    max-width: 100%;
  }
`;

export { StyledPreTitle, StyledTitle };
